import React, { useState } from "react";

import { useSmartObject, useConnection } from "@eveworld/contexts";

const WelcomePlayer = React.memo(() => {
  const { smartCharacter } = useSmartObject();
  useConnection();

  if (smartCharacter === undefined) return <></>;
  if (smartCharacter.name === "") return <></>;

  const [welcomeMessage, setState] = useState("Welcome " + smartCharacter.name);

  return (
    <>
      <div
        className="Quantum-Container font-semibold grid grid-cols-3 gap-2"
        id="SmartObject-Actions"
      >
        <div
          className="font-normal !py-4 text-center bg-crude"
          id="error-message"
        >
          {welcomeMessage}
        </div>
      </div>

      <div
        className="Quantum-Container font-normal text-xs !py-4 bg-crude"
        id="instructions-description"
      >
        This gate requires a valid jump gate ticket. If you do not have an
        active ticket, please head to the nearby ticketing machine. Access time
        is paid for with Salt.
      </div>
    </>
  );
});

export default React.memo(WelcomePlayer);
