const ISmartGates = {
  // address: "0x5a5a7689d2baf7e8a74fa73ea47493a0bf4deb2fcac3c962643a02c77c53a744",
  address: "0x73797475686500000000000000000000536d6172744761746553797374656d00",
  abi: [
    {
      type: "function",
      name: "tuhe__executeGateAccess",
      inputs: [
        {
          name: "smartCharacterId",
          type: "uint256",
          internalType: "uint256",
        },
        {
          name: "couldAccess",
          type: "bool",
          internalType: "bool",
        },
      ],
      outputs: [],
      stateMutability: "nonpayable",
    }
  ]
} as const;

export default ISmartGates;
