import { defineWorld } from "@latticexyz/world";

export default defineWorld({
  namespace: "tuhe",
  tables: {
    GateAccess: {
      schema: {
        smartCharacterId: "address",
        smartGateId: "uint256",
        couldJump: "string",
      },
      key: ["smartCharacterId", "smartGateId"],
    },
    GateTickets: {
      schema: {
        smartCharacterId: "uint256",
        smartStorageUnitId: "uint256", //where was the ticket bought
        ticketExpiry: "uint256",
      },
      key: ["smartCharacterId"],
    },
  },
});
