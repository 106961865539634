import React, { ReactNode, createContext, useMemo } from "react";
import {
  Client,
  getContract,
  GetContractReturnType,
  PublicClient,
  WalletClient,
} from "viem";
import ISmartGates from "./ISmartGates";
import { useConnection } from "@eveworld/contexts";

interface SmartGatesContract {
  SmartGatesContract: GetContractReturnType<
    typeof ISmartGates.abi,
    Client
  > | null;
}

const SmartGatesProvider = ({ children }: { children: ReactNode }) => {
  const { defaultNetwork, publicClient, walletClient } = useConnection();

  const SmartGatesContract = useMemo(
    () =>
      getContract({
        abi: ISmartGates.abi,
        address: defaultNetwork.worldAddress,
        client: {
          public: publicClient as PublicClient,
          wallet: walletClient as WalletClient,
        },
      }),
    [publicClient, walletClient]
  );

  return (
    <SmartGatesContext.Provider value={{ SmartGatesContract }}>
      {children}
    </SmartGatesContext.Provider>
  );
};

export const SmartGatesContext = createContext<SmartGatesContract>({
  SmartGatesContract: null,
});

export default SmartGatesProvider;
