import React, { useEffect, useContext } from "react";
// import { useMUD } from "../MUDContext";

import {
  useSmartObject,
  useNotification,
  useConnection,
} from "@eveworld/contexts";
import { Severity, SmartAssemblyType } from "@eveworld/types";
import {
  EveLoadingAnimation,
  ErrorNotice,
  ErrorNoticeTypes,
  // EveButton,
} from "@eveworld/ui-components";

import { JSX } from "react/jsx-runtime";
//import { findOwnerByAddress } from "@eveworld/utils";

import { SmartGatesContext } from "../src/contract/SmartGatesContext.tsx";

import WelcomePlayer from "./WelcomePlayer";
import moment from "moment";

import { setup } from "../mud/setup.ts";

const { network } = await setup();

const EntityView = React.memo((): JSX.Element => {
  const { SmartGatesContract } = useContext(SmartGatesContext);
  const { smartAssembly, smartCharacter, loading } = useSmartObject();
  const { notify, handleClose } = useNotification();

  const { walletClient, publicClient } = useConnection();

  // const {
  //   network: { tables, useStore },
  // } = useMUD();

  useEffect(() => {
    if (loading) {
      notify({ type: Severity.Info, message: "Loading..." });
    } else {
      handleClose();
    }
  }, [loading]);

  if ((!loading && !smartAssembly) || !smartAssembly?.isValid) {
    return <ErrorNotice type={ErrorNoticeTypes.SMART_ASSEMBLY} />;
  }

  var smartGate = smartAssembly as SmartAssemblyType<"SmartGate">;

  const key = {
    smartCharacterId: BigInt(smartCharacter.id),
  };

  const rec = network.useStore
    .getState()
    .getRecord(network.tables.GateTickets, key);

  var dateFormatted = "";
  if (rec?.fields.ticketExpiry) {
    // var d = new Date();
    // var seconds = d.getTime() * 100;

    var secondsSinceEpoch = Math.floor(Date.now() / 1000);

    var startDate = moment(secondsSinceEpoch * 1000);
    var timeEnd = moment(Number(rec?.fields.ticketExpiry) * 1000);
    var diff = timeEnd.diff(startDate);
    var diffDuration = moment.duration(diff);

    // console.log("Total Duration in millis:", diffDuration.asMilliseconds());
    // console.log("Days:", diffDuration.days());
    // console.log("Hours:", diffDuration.hours());
    // console.log("Minutes:", diffDuration.minutes());
    // console.log("Seconds:", diffDuration.seconds());

    if (diffDuration.seconds() < 0) {
      dateFormatted = "You do not have a valid ticket for this journey.";
    } else {
      dateFormatted =
        "Your ticket expires in " +
        diffDuration.days().toString() +
        "d " +
        diffDuration.hours().toString() +
        "h " +
        diffDuration.minutes().toString() +
        "m " +
        diffDuration.seconds().toString() +
        "s ";
      ("approximately.");
    }
  }

  const handleCouldAccess = async () => {
    const getTransactionRequest = async () => {
      if (
        !walletClient ||
        !walletClient?.account ||
        !publicClient ||
        !SmartGatesContract ||
        !smartAssembly
      )
        return;

      return publicClient.simulateContract({
        address: SmartGatesContract.address,
        abi: SmartGatesContract.abi,
        functionName: "tuhe__executeGateAccess",
        args: [BigInt(smartGate.id.toString()), true],
        account: walletClient.account.address,
      });
    };

    if (
      !walletClient ||
      !walletClient.account ||
      !SmartGatesContract ||
      !smartAssembly
    )
      return;

    try {
      const request = getTransactionRequest();

      if (!request) return; //return if simulateContract failed

      //console.info("");
      if (walletClient.account) {
        walletClient.writeContract({
          address: SmartGatesContract.address,
          abi: SmartGatesContract.abi,
          functionName: "tuhe__executeGateAccess",
          args: [BigInt(smartGate.id.toString()), true],
          account: walletClient.account.address,
          chain: walletClient.chain,
        });
      }

      //errorMessage = "Process complete, leaderboard is cleared!";
      //console.info(errorMessage);
      return;
    } catch (e: any) {
      //errorMessage = e.message;
      console.error(e);
    }
  };

  return (
    <>
      <EveLoadingAnimation position="diagonal">
        {/* <div className="grid border border-brightquantum bg-crude"> */}
        <div className="grid border border-brightquantum bg-crude">
          <WelcomePlayer></WelcomePlayer>
        </div>
        <div className="grid border border-brightquantum bg-crude">
          <div className="text-l my-2 text-center">
            <p>{smartGate.name}</p>
            <p> {dateFormatted}</p>
          </div>
        </div>
      </EveLoadingAnimation>
    </>
  );
});

export default React.memo(EntityView);
